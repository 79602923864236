import { userStore } from '@context/userStore';
import { useRouter } from 'next/router';
import '../styles/globals.css';

import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { classNames } from '@helpers/classNames';
import { EnvProvider } from '@helpers/envProvider';
import { getApplicationToken } from '@helpers/getApplicationToken';
import Plausible from '@helpers/plausible';
import { getUserSession } from '@providers/session';
import { usePlausible } from 'next-plausible';
import App from 'next/app';
import { Lato, Roboto } from 'next/font/google';
import Head from 'next/head';
import { useEffect, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
config.autoAddCss = false;

const roboto = Roboto({
  subsets: ['latin'],
  weight: ['100', '300', '400', '700', '900'],
  variable: '--font-roboto',
});

const lato = Lato({
  subsets: ['latin'],
  weight: ['100', '300', '400', '700', '900'],
  variable: '--font-lato',
});

function Platform({ Component, pageProps, envs }) {
  const plausibleEvent = usePlausible();
  const environmentVariables = useState(envs);
  const router = useRouter();

  const { profileId, organizations } = userStore();

  useEffect(() => {
    const handleRouteChange = (url) => {
      const eventProps = {};
      if (profileId) eventProps.profileId = profileId;
      if (organizations && organizations.length > 0) {
        eventProps.organizationName = organizations.join(', ');
      }
      eventProps.url = url;

      plausibleEvent('pageview', {
        props: eventProps,
      });
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [profileId, organizations, plausibleEvent, router]);

  useEffect(() => {
    const fetchUserSession = async () => {
      await getUserSession();
    };
    fetchUserSession();
  }, []);

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" href="/favicon.ico" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#edb94a" />
        <meta name="msapplication-TileColor" content="#edb94a" />
        <meta name="theme-color" content="#edb94a" />
      </Head>

      <EnvProvider envs={envs}>
        <Plausible environment={environmentVariables[0]['NEXT_PUBLIC_ENVIRONMENT']} />
        <div className={classNames(`${lato.variable} ${roboto.variable}`, 'overflow-x-hidden')}>
          <Component {...pageProps} />
        </div>
      </EnvProvider>
    </>
  );
}

Platform.getInitialProps = async (appContext) => {
  const { ctx } = appContext;
  const pageProps = await App.getInitialProps(appContext);

  if (ctx.req && ctx.res) {
    await getApplicationToken(ctx.req, ctx.res);
  }

  const envs = Object.keys(process.env)
    .filter((key) => key.startsWith('NEXT_PUBLIC_'))
    .reduce((acc, key) => {
      acc[key] = process.env[key];
      return acc;
    }, {});

  return {
    ...pageProps,
    envs: envs,
  };
};

export default Platform;
