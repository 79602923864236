import axios from 'axios';
import Cookies from 'cookies';
import { decrypt, encrypt } from './applicationTokenEncryption';

export async function getApplicationToken(req, res) {
  const API_URL = process.env.NEXT_PUBLIC_API;
  const cookies = new Cookies(req, res);

  let applicationToken = cookies.get('application_token');
  let applicationTokenExpiry = cookies.get('application_token_expiry');

  if (!applicationTokenExpiry || new Date(applicationTokenExpiry) <= new Date()) {
    try {
      const response = await axios.post(`${API_URL}/auth/token`, {
        client_id: process.env.FRONTEND_CLIENT_ID,
        client_secret: process.env.FRONTEND_CLIENT_SECRET,
      });

      if (response && response.status === 200) {
        const { application_token: newApplicationToken, expiration_date } = response.data;
        const encryptedToken = encrypt(newApplicationToken);

        applicationToken = encryptedToken;
        applicationTokenExpiry = expiration_date;

        cookies.set('application_token', encryptedToken, {
          httpOnly: true,
          sameSite: 'strict',
        });

        cookies.set('application_token_expiry', new Date(applicationTokenExpiry), {
          httpOnly: true,
          sameSite: 'strict',
        });
      } else {
        cookies.set('application_token');
        cookies.set('application_token_expiry');
      }
    } catch (error) {
      return { error: 'unauthorized' };
    }
  }
  return decrypt(applicationToken);
}
