import * as Sentry from '@sentry/browser';
import cookieCutter from 'cookie-cutter';
import { clientApi } from './clientApi';

export async function getUserSession() {
  try {
    const response = await clientApi('/session/user', 'GET');
    const user_session_id = response.data.user_session;
    cookieCutter.set('user_session_id', user_session_id, { path: '/' });
    return user_session_id;
  } catch (error) {
    Sentry.captureException(error);
  }
}

export function deleteUserSession() {
  cookieCutter.set('user_session_id', '', { path: '/', expires: new Date(0) });
}
