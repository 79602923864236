import { createContext, useContext, useState } from 'react';

const EnvContext = createContext();

export const EnvProvider = ({ children, envs }) => {
  const [environment, setEnvironment] = useState(envs);

  const updateEnvironment = (newEnvironment) => {
    setEnvironment(newEnvironment);
  };

  return (
    <EnvContext.Provider value={{ environment, updateEnvironment }}>{children}</EnvContext.Provider>
  );
};

export const useEnvContext = () => {
  return useContext(EnvContext);
};
