import crypto from 'crypto';

const ENCRYPTION_SECRET_KEY = process.env.ENCRYPTION_SECRET_KEY;

export function encrypt(data) {
  const cipher = crypto.createCipher('aes-256-cbc', ENCRYPTION_SECRET_KEY);
  let encryptedData = cipher.update(data, 'utf-8', 'hex');
  encryptedData += cipher.final('hex');
  return encryptedData;
}

export function decrypt(data) {
  const decipher = crypto.createDecipher('aes-256-cbc', ENCRYPTION_SECRET_KEY);
  let decryptedData = decipher.update(data, 'hex', 'utf-8');
  decryptedData += decipher.final('utf-8');
  return decryptedData;
}
