const domains = {
  dev: 'localhost',
  staging: 'staging.isso.dev',
  beta: 'beta.isso.dev',
  prod: 'bouwzo.nl',
};

export const getDomain = (environment) => {
  return domains[environment] || domains.dev;
};
